import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useFiltersNovedadesProvMxStore = defineStore({
  id: 'filtersNovedadesProvMx',
  state: () => ({
    _filters: {
      fechaInicial: '',
      fechaFinal: '',
      numFactura: '',
      proveedor: '',
      enviada: ''
    },
    _refrescarListadoNovedadesProvMx: false
  }),
  getters: {
    getFilters: (state) => state._filters,
    getRefrescarListadoNovedadesProvMx: (state) => state._refrescarListadoNovedadesProvMx
  },
  actions: {
    setUpdateFilters (filters) {
      this._filters = filters
    },
    setRefrescarListadoNovedadesProvMx (payload) {
      this._refrescarListadoNovedadesProvMx = payload
    }
  }
})
