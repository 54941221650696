<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useFiltersNovedadesProvMxStore } from '../../../store/filtersNovedadesProvMx.store'
import NovedadesProveedoresMxService from '@/apps/pharmasan/compras/logistica/services/novedadesProveedoresMx.service'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const _NovedadesProveedoresMxService = ref(new NovedadesProveedoresMxService())
const filtersNovedadesProvMxStore = useFiltersNovedadesProvMxStore()
const listProveedores = ref([])
const filters = reactive({
  fechaInicial: '',
  fechaFinal: '',
  numFactura: '',
  proveedor: '',
  enviada: ''
})

// Validar que la fecha final no sea menor a la fecha inicial
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    return true
  } else {
    return false
  }
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  if (validateFechaFinal(filters.fechaInicial, filters.fechaFinal)) {
    return
  }

  const filtersData = {
    fechaInicial: filters.fechaInicial ?? '',
    fechaFinal: filters.fechaFinal ?? '',
    numFactura: filters.numFactura ?? '',
    // Pasar de array a string separado por comas
    proveedor: filters.proveedor !== '' ? filters.proveedor?.map((item) => item).join(',') ?? '' : '',
    enviada: filters.enviada ?? ''
  }

  filtersNovedadesProvMxStore.setUpdateFilters(filtersData)
}

// Limpiar los filtros
const resetClear = () => {
  for (const [key] of Object.entries(filtersNovedadesProvMxStore.getFilters)) {
    filters[key] = ''
  }
  filtersNovedadesProvMxStore.setUpdateFilters(filters)
}

const reload = () => {
  filtersNovedadesProvMxStore.setRefrescarListadoNovedadesProvMx(true)
}

// Obtener los proveedores con novedades
const fetchProveedoresWithNovedad = async () => {
  await _NovedadesProveedoresMxService.value.listarProveedoresWithNovedad().then((res) => {
    listProveedores.value = res.data
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Hook onMounted
onMounted(async () => {
  await fetchProveedoresWithNovedad()

  // Setear los filtros del store al filters reactive
  for (const [key, value] of Object.entries(filtersNovedadesProvMxStore.getFilters)) {
    filters[key] = value
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2">

    <div class="col-span-12 sm:col-span-2">
      <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
      <InputText
        id="fechaInicial"
        type="date"
        class="w-full text-sm"
        v-model="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaInicial ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
      <InputText
        id="fechaFinal"
        type="date"
        class="w-full text-sm"
        v-model="filters.fechaFinal"
        :min="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaFinal ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="numFactura" class="block text-xs font-medium text-gray-700 mb-2">Número Factura:</label>
      <InputText
        id="numFactura"
        type="text"
        class="w-full text-sm"
        placeholder="Buscar por número de factura..."
        v-model="filters.numFactura"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.numFactura ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="enviada" class="block text-sm font-medium text-gray-700">Enviada?:</label>
      <select
        class="form-select form-select-sm mt-1"
        id="enviada"
        v-model="filters.enviada"
        :style="$route.query.enviada ? 'border-color: #689f38 !important' : ''"
      >
        <option value="">- Seleccione una opción -</option>
        <option value="0">No</option>
        <option value="1">Si</option>
      </select>
    </div>

    <div class="col-span-12 sm:col-span-4">
      <label for="proveedor" class="block text-xs font-medium text-gray-700 mb-2">Proveedor:</label>
      <MultiSelect
        id="proveedor"
        v-model="filters.proveedor"
        :options="listProveedores"
        optionLabel="cardName"
        optionValue="cardCode"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        display="chip"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.proveedor ? 'border-color: #689f38' : '')
          }),
          label: { style: 'font-size: 0.7rem; padding: 0.2rem 0.5rem' },
          token: { style: 'font-size: 0.6rem; padding: 0.1rem 0.5rem' },
        }"
      />
    </div>

    <div class="col-span-12 lg:col-span-12">
      <div class="flex flex-wrap justify-end items-end h-full gap-2 w-full">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-refresh text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="reload()"
          v-tippy="{ content: 'Recargar' }"
        />
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
