<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import NovedadesProveedoresMxService from '@/apps/pharmasan/compras/logistica/services/novedadesProveedoresMx.service'
import Filters from '../filters/index.vue'
import { useFiltersNovedadesProvMxStore } from '../../../store/filtersNovedadesProvMx.store'
import { useRoute, useRouter } from 'vue-router'
import Icon from '@/components/Icon/index.vue'
import Swal from 'sweetalert2'
import { error } from '../../../../../../../libs/mensajes'

const filtersNovedadesProvMxStore = useFiltersNovedadesProvMxStore()
const _NovedadesProveedoresMxService = ref(new NovedadesProveedoresMxService())
const listNovedades = ref([])
const infoProveedor = ref({})
const route = useRoute()
const router = useRouter()
const limit = ref(20)
const offset = ref(0)
const orderField = ref('createdAt')
const sortOrder = ref(-1)
const expandedRows = ref([])

// Computada para obtener el store de filtros
const getterFiltersNovedadesProvMx = computed(() => {
  return filtersNovedadesProvMxStore.getFilters
})

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'createdAt,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

// Obtener el listado de las novedades de los provedores
const fetchListNovedades = async () => {
  await _NovedadesProveedoresMxService.value.listarNovedades(setQueryParams()).then(response => {
    listNovedades.value = response.data
  }).catch(error => {
    console.log(error)
  })
}

// Funcion para obtener y manejar mejor el estado de la solicitud
const getStatusInfo = (data) => {
  if (!data.enviada) {
    return {
      class: 'bg-blue-100 text-blue-800 border-blue-400',
      text: 'SIN ENVIAR'
    }
  } else {
    return {
      class: 'bg-green-100 text-green-800 border-green-400',
      text: 'ENVIADA'
    }
  }
}

// Buscar el proveedor
const fetchEmailProveedor = async (cardCode) => {
  await _NovedadesProveedoresMxService.value.searchProveedor(cardCode).then(response => {
    infoProveedor.value = response.data
  }).catch(error => {
    console.log(error)
  })
}

// Enviar novedad
const enviarNovedad = async (data) => {
  const { id } = data

  if (id === null) {
    error('No se puede enviar la novedad, por favor intente nuevamente')
    return
  }

  // Obtener el email del proveedor
  await fetchEmailProveedor(data.cardCode)

  Swal.fire({
    icon: 'info',
    title: 'Información',
    html: `
      <div class="flex flex-col">
        <span class="">Desea enviar esta novedad al proveedor?, esta acción no se puede revertir!</span>
        <p class="text-xxs mt-4">
          <span class="font-semibold">Nota:</span>
          <span>
            Si la novedad es enviada, no se podrá editar ni eliminar posteriormente.
          </span>
        </p>
        <p class="text-xs mt-4">
          <span>
            Ingrese los correos electrónicos separados por coma (,) a los cuales desea enviar la novedad:
          </span>
        </p>
      </div>
    `,
    input: 'textarea',
    inputPlaceholder: 'Email del proveedor',
    inputAttributes: {
      'aria-label': 'Email del proveedor'
    },
    inputValue: infoProveedor.value.email,
    inputValidator: (value) => {
      // Validar que no este vacio
      if (!value) {
        return 'Por favor ingrese el email del proveedor'
      }
      // Expresión regular para validar correos electrónicos
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      // Dividir correos electrónicos por coma
      const emails = value.split(',')

      // Validar cada correo electrónico en la lista
      for (const email of emails) {
        if (!emailRegex.test(email.trim())) {
          return 'Por favor ingrese correos electrónicos válidos'
        }
      }
    },
    showDenyButton: true,
    allowOutsideClick: false,
    denyButtonText: 'No, Cancelar',
    confirmButtonText: 'Si, Enviar'
  }).then((result) => {
    if (result.isConfirmed) {
      // Obtener los correos electrónicos sin espacios entre comas y string
      const emails = result.value.split(',').map(email => email.trim()).join(',')

      _NovedadesProveedoresMxService.value.enviarNovedad(data.id, { emails }).then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          html: `${response.data.message}`
        }).then(() => {
          fetchListNovedades()
        })
      }).catch((err) => {
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    }
  })
}

// Editar la novedad
const editarNovedad = (data) => {
  const { id, enviada } = data

  if (![null, undefined, false].includes(enviada)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'No se puede editar la novedad, ya fue enviada al proveedor'
    })
    return
  }

  router.push({ name: 'pharmasan.compras.logistica.novedades-proveedores-mx.editar', params: { id } })
}

// Eliminar la novedad
const eliminarNovedad = (data) => {
  const { id, enviada } = data

  if (![null, undefined, false].includes(enviada)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'No se puede eliminar la novedad, ya fue enviada al proveedor'
    })
    return
  }

  if (id) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: `Desea eliminar la novedad #${id}?, esta acción no se puede revertir.`,
      showDenyButton: true,
      denyButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, Eliminar',
      focusDeny: true
    }).then((result) => {
      if (result.isConfirmed) {
        _NovedadesProveedoresMxService.value.eliminarNovedad(id).then(({ status }) => {
          if (status !== 200) return

          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'La novedad se ha eliminado correctamente'
          }).then(() => {
            fetchListNovedades()
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    console.error('Error al eliminar la novedad, por favor intente nuevamente')
  }
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  // Si el objeto no viene vacio
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    filtersNovedadesProvMxStore.setUpdateFilters(route.query)
  } else {
    filtersNovedadesProvMxStore.setUpdateFilters({})
  }
}

// Expandir y contraer filas
const onRowExpand = (event) => {
  console.log('onRowExpand', event)
}
const onRowCollapse = (event) => {
  console.log('onRowCollapse', event)
}

// Watch si cambian los filtros
watch(getterFiltersNovedadesProvMx, async (newFilter) => {
  _routerPush(newFilter)
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async (value) => {
  await fetchListNovedades()
})

// Watch para refrescar la tabla
watch(() => filtersNovedadesProvMxStore.getRefrescarListadoNovedadesProvMx, async (value) => {
  if (value) {
    await fetchListNovedades()
    filtersNovedadesProvMxStore.setRefrescarListadoNovedadesProvMx(false)
  }
})

// Hook
onMounted(async () => {
  await getRouteQuery()
  await fetchListNovedades()
})
</script>

<template>
  <div class="box intro-x">
    <div class="mt-4">

      <!-- Titulo Card -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado</h3>
        <div class="flex">
        </div>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4 gap-5">

        <!-- Filtros -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <Filters />
        </div>

        <!-- Tabla listado -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listNovedades.data"
            v-model:expandedRows="expandedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            dataKey="id"
            @sort="orderQuery"
            :sortField="orderField"
            :sortOrder="sortOrder"
          >
            <template #empty> No hay datos para mostrar. </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column expander style="width: 3rem" />
            <Column field="id" header="Id" :sortable="true"></Column>
            <Column field="createdAt" header="Fecha" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <!-- <span>Novedad: {{ $h.formatDate(data.fecha, 'YYYY-MM-DD') }}</span>-->
                  <span>{{ $h.formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </template>
            </Column>
            <Column field="numFactura" header="Num. Factura" :sortable="true"></Column>
            <Column field="cardName" header="Proveedor" :sortable="true">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.cardName }}</span>
                  <span class="text-xs text-gray-700">{{ data.cardCode }}</span>
                </div>
              </template>
            </Column>
            <Column field="comentarios" header="Comentarios" :sortable="false"></Column>
            <Column field="usuario" header="Usuario" :sortable="false"></Column>
            <Column header="Estado" :exportable="false" style="max-width:6rem" >
              <template #body="{ data }">
                <div class="flex flex-col flex-wrap justify-center">
                  <button
                    v-if="data.enviada"
                    class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                    :class="getStatusInfo(data).class"
                    v-tippy="{ content: `
                      <div class='flex flex-col' v-if='${data.enviada}'>
                        <div class='flex flex-wrap justify-between border-b border-dashed'>
                          <span class='font-semibold'>Enviada por:</span>
                          <span class='text-xs mt-1'>${data.enviadaBy}</span>
                        </div>
                        <div class='flex flex-wrap justify-between border-b border-dashed'>
                          <span class='font-semibold'>Fecha de envío:</span>
                          <span class='text-xs mt-1'>${$h.formatDate(data.enviadaAt, 'YYYY-MM-DD HH:mm:ss') }</span>
                        </div>
                        <div class='flex flex-wrap justify-between'>
                          <span class='font-semibold'>Enviada a:</span>
                          <span class='text-xs mt-1'>${data.enviadaTo}</span>
                        </div>
                      </div>
                    `, trigger: 'click' }"
                  >
                    {{ getStatusInfo(data).text }}
                  </button>
                  <button
                    v-else
                    class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                    :class="getStatusInfo(data).class"
                  >
                    {{ getStatusInfo(data).text }}
                  </button>
                </div>
              </template>
            </Column>
            <Column
              header="Acciones"
              style="max-width:7rem"
              :exportable="false"
              :pt="{
                headerContent: {
                  style: 'width: 100%; display: flex; justify-content: center; align-items: center;'
                },
              }"
            >
              <template #body="{data}">
                <div class="flex justify-center">
                  <Button
                    icon="pi"
                    severity="secondary"
                    :class="`p-button-rounded mr-2`"
                    v-tippy="{ content: `Enviar` }"
                    @click="enviarNovedad(data)"
                    v-if="!data.enviada"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  >
                    <Icon icon="fa:send" class="text-base"/>
                  </Button>
                  <Button
                    icon="pi pi-pencil"
                    severity="secondary"
                    :class="`p-button-rounded mr-2`"
                    v-tippy="{ content: `Editar` }"
                    @click="editarNovedad(data)"
                    v-if="!data.enviada"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  />
                  <Button
                    icon="pi pi-trash"
                    severity="secondary"
                    :class="`p-button-rounded`"
                    v-tippy="{ content: `Eliminar` }"
                    @click="eliminarNovedad(data)"
                    v-if="!data.enviada"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  />
                </div>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div v-if="slotProps.data.Detalle.length === 0" class="w-full flex flex-col items-center justify-center">
                <Icon icon="healthicons:not-ok" class="text-5xl text-gray-500"/>
                <span class="text-red-800 opacity-70">No hay detalle</span>
              </div>
              <div class="" v-else>
                <h5 class="text-sm font-semibold text-gray-600 mb-2">Información de los articulos</h5>
                <DataTable
                  :value="slotProps.data.Detalle"
                  showGridlines
                  responsiveLayout="scroll"
                  class="p-datatable-sm text-xs"
                >
                  <Column field="linea" header="Linea" />
                  <Column field="itemCode" header="Cod. Mx" />
                  <Column field="itemName" header="Nom. Mx" />
                  <Column field="cantidad" header="Cantidad" />
                  <Column field="tipoNovedad" header="Novedad" />
                </DataTable>
              </div>
            </template>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listNovedades.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listNovedades.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              },
            }"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.swal2-textarea {
  font-size: 1rem !important;
}
</style>
