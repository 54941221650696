import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_NOVEDADES_PROVEEDORES_MX

export default class NovedadesProveedoresMxService {
  // Obtener los proveedores
  getProveedores (params) {
    return http.get(`${baseUrl}/proveedores`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Listar los proveedores con novedades
  listarProveedoresWithNovedad () {
    return http.get(`${baseUrl}/proveedores/listar-proveedores-novedad`, {
      headers: {
        loading: true
      }
    })
  }

  // Buscar el proveedor
  searchProveedor (params) {
    return http.get(`${baseUrl}/proveedores/search`, {
      params: { cardCode: params },
      headers: {
        loading: true
      }
    })
  }

  // Obtener los articulos
  getAllMds (payload) {
    return http.get(`${baseUrl}/articulos/search-mx`, {
      params: { search: payload },
      headers: {
        loading: true
      }
    })
  }

  // Obtener los tipos de novedades
  getTiposNovedades () {
    return http.get(`${baseUrl}/novedades/tipos`, {
      headers: {
        loading: true
      }
    })
  }

  // Guardar las novedades
  saveNovedades (payload) {
    return http.post(`${baseUrl}/novedades`, payload, {
      headers: {
        loading: true
      }
    })
  }

  // Listar las novedades
  listarNovedades (params) {
    return http.get(`${baseUrl}/novedades/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Enviar las novedades al proveedor
  enviarNovedad (id, body) {
    return http.put(`${baseUrl}/novedades/enviar/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar la novedad
  eliminarNovedad (id) {
    return http.delete(`${baseUrl}/novedades/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener info de la novedad
  getNovedad (id) {
    return http.get(`${baseUrl}/novedades/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  // Editar la novedad
  editarNovedad (id, body) {
    return http.put(`${baseUrl}/novedades/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }
}
